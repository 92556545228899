import * as React from 'react';
import { Seo } from '../components/seo';
import { Layout } from '../layout';
import { BreadCrumb } from '../section/breadcrumb';

import { CommitmentSection } from '../section/main-quality/commitment';
import { FlexibilitySection } from '../section/main-quality/flexibility';
import { SpeedSection } from '../section/main-quality/speed';
import { AfterFollowSection } from '../section/main-quality/after-follow';

import BG_QUALITY from '../assets/img/bg-quality.jpeg';

const MainQualityPage = () => {
  return (
    <Layout>
      <Seo
        title="FINEのこだわり"
        description="株式会社FINEは「クオリティーの追求」、「イレギュラーに対応できる柔軟性」、「スピード」、「アフターフォロー」の4つにこだわりをもった仕事をしています。"
      />
      <section className="mb-6">
        <div
          style={{ background: `url(${BG_QUALITY})` }}
          className="relative flex items-center justify-center 2xl:-mt-2 w-screen h-40vh bg-cover bg-center overflow-hidden md:h-60vh"
        >
          <h1 className="flex flex-col items-center justify-center tracking-widest">
            <span className="text-white text-2xl md:text-4xl">OUR QUALITY</span>
            <span className="mt-2 text-white text-xs md:text-base">
              FINEのこだわり
            </span>
          </h1>
          <div>
            <svg
              className="wave absolute bottom-0 inset-x-0 -mb-1 w-200vw text-white fill-current"
              viewBox="0 0 1440 64"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
            </svg>
          </div>
        </div>
      </section>
      {/* <!-- 現在ページ --> */}
      <BreadCrumb title="FINEのこだわり" />
      <section className="mb-12 md:mb-24 lg:mb-32">
        <CommitmentSection />
        <FlexibilitySection />
        <SpeedSection />
        <AfterFollowSection />
      </section>
    </Layout>
  );
};

export default MainQualityPage;
