import * as React from 'react';
import BG_QUALITY_3 from '../../assets/img/bg-quality-3.jpeg';

export const SpeedSection = () => {
  return (
    <div className="w-screen bg-primary-bg">
      <div className="relative flex flex-wrap mx-auto p-4 max-w-5xl md:px-10 md:py-8 lg:py-16">
        <div className="flex mx-auto w-full max-w-5xl lg:ml-auto lg:mx-0 lg:w-2/3">
          <img
            className="w-full h-40 object-cover md:h-80 lg:h-full"
            src={BG_QUALITY_3}
            alt=""
          />
        </div>
        <div className="flex flex-wrap items-center justify-center p-4 w-full bg-white transform md:p-8 lg:absolute lg:top-1/2 lg:p-12 lg:w-1/2 lg:-translate-y-1/2">
          <div className="flex flex-wrap items-center mb-4 w-full md:mb-6 lg:mb-8">
            <svg
              className="mb-2 mx-auto w-8 text-primary-blue fill-current md:mb-0 md:mx-0 md:w-12"
              viewBox="0 0 56 56"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M35.5878 45.4288C44.0785 45.4288 50.9861 38.5211 50.9861 30.0305C50.9861 21.5398 44.0785 14.632 35.5878 14.632C27.0971 14.632 20.1894 21.5397 20.1894 30.0305C20.1894 38.5212 27.0971 45.4288 35.5878 45.4288ZM24.1227 29.2054H21.8646C22.0532 26.0347 23.321 23.1502 25.3053 20.9145L26.8975 22.5067C27.0585 22.6678 27.2697 22.7483 27.4807 22.7483C27.6918 22.7483 27.903 22.6678 28.064 22.5067C28.3862 22.1845 28.3862 21.6622 28.064 21.3401L26.4718 19.748C28.7075 17.7637 31.5921 16.4959 34.7628 16.3072V18.5654C34.7628 19.021 35.132 19.3903 35.5877 19.3903C36.0434 19.3903 36.4126 19.021 36.4126 18.5654V16.3072C39.5833 16.4959 42.4678 17.7637 44.7034 19.748L43.1113 21.3401C42.7891 21.6623 42.7891 22.1846 43.1113 22.5067C43.2724 22.6677 43.4836 22.7483 43.6946 22.7483C43.9057 22.7483 44.1169 22.6678 44.278 22.5067L45.8701 20.9145C47.8542 23.1502 49.1222 26.0346 49.3107 29.2053H47.0525C46.5969 29.2053 46.2276 29.5747 46.2276 30.0302C46.2276 30.4858 46.5969 30.8551 47.0525 30.8551H49.3107C49.1221 34.0258 47.8542 36.9104 45.87 39.146L44.2779 37.5539C43.9556 37.2318 43.4334 37.2318 43.1113 37.5539C42.7891 37.8761 42.7891 38.3984 43.1113 38.7204L44.7034 40.3126C42.4677 42.2969 39.5833 43.5647 36.4126 43.7533V41.4952C36.4126 41.0396 36.0434 40.6703 35.5877 40.6703C35.132 40.6703 34.7628 41.0396 34.7628 41.4952V43.7533C31.592 43.5647 28.7075 42.2969 26.4718 40.3126L28.064 38.7205C28.3862 38.3984 28.3862 37.8761 28.064 37.554C27.7417 37.2318 27.2195 37.2319 26.8975 37.554L25.3053 39.1461C23.321 36.9104 22.0532 34.026 21.8646 30.8551H24.1227C24.5783 30.8551 24.9476 30.4858 24.9476 30.0302C24.9476 29.5748 24.5783 29.2054 24.1227 29.2054Z" />
              <path d="M45.59 45.9074C42.6001 47.7951 39.1414 48.7928 35.5878 48.7928C25.2422 48.7928 16.8254 40.376 16.8254 30.0304C16.8254 25.842 18.175 21.8804 20.728 18.574C21.0064 18.2135 20.9398 17.6954 20.5792 17.4169C20.2187 17.1386 19.7006 17.2051 19.4221 17.5657C16.644 21.1635 15.1756 25.4737 15.1756 30.0304C15.1756 41.2857 24.3325 50.4426 35.5878 50.4426C39.4537 50.4426 43.217 49.3567 46.4707 47.3023C46.856 47.0592 46.9711 46.5497 46.7279 46.1644C46.4846 45.7792 45.9752 45.6643 45.59 45.9074Z" />
              <path d="M40.5627 10.2324V7.10549C40.5627 6.25177 39.8681 5.55731 39.0145 5.55731H32.1608C31.3071 5.55731 30.6126 6.25177 30.6126 7.10549V10.2315C27.3322 11.0534 24.2888 12.6838 21.7557 15.0191C21.4208 15.3279 21.3995 15.8497 21.7083 16.1847C22.0171 16.5196 22.539 16.541 22.8739 16.2321C26.3463 13.0309 30.8615 11.268 35.5877 11.268C45.9333 11.268 54.3501 19.6847 54.3501 30.0304C54.3501 35.3406 52.0847 40.4254 48.1347 43.9804C47.7961 44.2851 47.7686 44.8067 48.0734 45.1453C48.2362 45.3264 48.461 45.4183 48.6867 45.4183C48.8834 45.4183 49.0807 45.3485 49.2384 45.2066C53.5354 41.3391 55.9999 35.8075 55.9999 30.0302C56 20.4906 49.4213 12.4595 40.5627 10.2324ZM32.2625 9.88883V7.20712H38.913V9.89048C37.8305 9.7123 36.72 9.61815 35.5879 9.61815C34.4652 9.61815 33.3537 9.70966 32.2625 9.88883Z" />
              <path d="M35.5878 30.6081C35.8973 30.6081 36.1666 30.4375 36.3077 30.1854L39.1533 27.3398C39.4754 27.0177 39.4754 26.4953 39.1533 26.1732C38.8311 25.851 38.3089 25.851 37.9865 26.1732L36.4126 27.747V23.7298C36.4126 23.2742 36.0434 22.9049 35.5877 22.9049C35.132 22.9049 34.7628 23.2742 34.7628 23.7298V29.783C34.7629 30.2388 35.1321 30.6081 35.5878 30.6081Z" />
              <path d="M12.1728 30.2166C12.1728 29.761 11.8035 29.3917 11.3479 29.3917H0.824904C0.369337 29.3917 0 29.761 0 30.2166C0 30.6721 0.369337 31.0415 0.824904 31.0415H11.3479C11.8035 31.0415 12.1728 30.6723 12.1728 30.2166Z" />
              <path d="M4.1151 26.2374H12.4479C12.9035 26.2374 13.2729 25.8681 13.2729 25.4125C13.2729 24.957 12.9035 24.5876 12.4479 24.5876H4.1151C3.65953 24.5876 3.29019 24.957 3.29019 25.4125C3.29019 25.8681 3.65942 26.2374 4.1151 26.2374Z" />
              <path d="M13.5477 19.7835H6.71387C6.2583 19.7835 5.88896 20.1528 5.88896 20.6084C5.88896 21.064 6.2583 21.4333 6.71387 21.4333H13.5477C14.0033 21.4333 14.3726 21.064 14.3726 20.6084C14.3726 20.1527 14.0033 19.7835 13.5477 19.7835Z" />
              <path d="M13.2727 34.6483C13.2727 34.1927 12.9034 33.8234 12.4478 33.8234H4.1151C3.65953 33.8234 3.29019 34.1927 3.29019 34.6483C3.29019 35.1038 3.65953 35.4732 4.1151 35.4732H12.4479C12.9034 35.4732 13.2727 35.1038 13.2727 34.6483Z" />
              <path d="M13.5477 38.6275H6.71387C6.2583 38.6275 5.88896 38.9968 5.88896 39.4524C5.88896 39.908 6.2583 40.2773 6.71387 40.2773H13.5477C14.0033 40.2773 14.3726 39.908 14.3726 39.4524C14.3726 38.9968 14.0033 38.6275 13.5477 38.6275Z" />
            </svg>

            <h3 className="w-full text-center text-primary-blue text-sm md:mx-4 md:w-fit-content md:text-base">
              スピード
            </h3>
          </div>
          <p className="mb-4 w-full text-xs tracking-wider leading-5 md:text-sm md:leading-6 lg:mb-0 lg:leading-7">
            たとえタイトな工期スケジュールでも、お客様と積極的にコミュニケーションをとり、妥協せずより良い施工を行います。
          </p>
        </div>
      </div>
    </div>
  );
};
